/** @jsx jsx */
import { jsx, Flex, Box, Text, Button } from 'theme-ui'
import React from 'react'
import { useAppContext } from 'utils'
import useRoom from 'hooks/use-room'
import differenceInCalendarDays from 'date-fns/fp/differenceInCalendarDays'
import { path, compose, prop, last } from 'ramda'

import { toPounds } from 'utils/currency'
import { Link } from 'gatsby'
import qs from 'query-string'

import { maybeParseDate } from '../utils/dates'

RoomAvailability.defaultProps = {
  identifier: 'salt-lake',
}

export function RoomAvailability() {
  const {
    activeRoom,
    startDate,
    endDate,
    niceStartDate,
    niceEndDate,
    niceEndDateMinusDay,
  } = useAppContext()

  const { costQuery, roomQuery } = useRoom({
    startDate,
    endDate,
    identifier: activeRoom,
  })

  const roomData = roomQuery?.data?.room
  const costData = costQuery?.data?.calculateCost
  const avGroups = roomData?.availabilityGroups ?? []

  const noDates = !niceStartDate && !niceEndDateMinusDay

  const firstAv = path([0, 'availabilities', 0, 'start'], avGroups)
  const lastAv = path([-2, 'availabilities', -1, 'end'], avGroups)

  const noData = noDates

  const totalCost = (costData?.cost ?? 0) / 100
  const nightlyCost = (roomData?.dailyCost ?? 0) / 100
  const resourceId = roomData?.id

  const noOfNights = differenceInCalendarDays(niceStartDate, niceEndDate)

  const plural = noOfNights === 1 ? 'night' : 'nights'

  const getArgs = () =>
    qs.stringify({
      id: resourceId,
      start: firstAv,
      end: lastAv,
      identifier: activeRoom,
    })

  const queryParams = noDates ? `` : `?${getArgs()}`

  return (
    <Box>
      <Flex
        sx={{
          justifyContent: 'space-between',
          alignItems: 'center',
          marginBottom: 'm',
        }}
      >
        {noData ? (
          <Text variant="stnd.body" sx={{ color: 'black.50' }}>
            Select your preferred dates
          </Text>
        ) : (
          <>
            <Text variant="stnd.body">
              {toPounds(nightlyCost)} × {noOfNights} {plural}
            </Text>
            <Text variant="stnd.body" sx={{ fontWeight: 'heading' }}>
              {toPounds(totalCost)}
            </Text>
          </>
        )}
      </Flex>
      <Box>
        <Button
          as={Link}
          disabled={noData}
          to={`/booking/${queryParams}`}
          sx={{
            width: '100% !important',
            opacity: noData && 0.5,
            pointerEvents: noData && 'none',
          }}
        >
          Book now
        </Button>
      </Box>
    </Box>
  )
}
