/** @jsx jsx */
import { jsx } from 'theme-ui'
import { Box, Grid, Text, Button } from 'theme-ui'
import { useStaticQuery, graphql } from 'gatsby'
import GatsbyImage from 'gatsby-image'

export function Gander() {
  const imagesQuery = useStaticQuery(graphql`
    query GanderImages {
      aquaduct: file(relativePath: { eq: "night-aquaduct@2x.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1680) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  return (
    <Grid sx={{ height: '100vh' }} variant="center.outer">
      <Grid variant="center.inner" sx={{ position: 'relative', zIndex: 1 }}>
        <GatsbyImage fluid={imagesQuery.aquaduct.childImageSharp.fluid} />
      </Grid>
      <Grid
        variant="center.inner"
        sx={{ position: 'relative', zIndex: 2 }}
        px={['m', null, 'l']}
      >
        <Box my="auto">
          <Box variant="boxes.container">
            <Text
              variant="subhead"
              sx={{
                color: 'white.100',
              }}
            >
              Have a gander
            </Text>
            <Text
              variant="heading.xl"
              mb="l"
              pt="l"
              sx={{
                color: 'white.100',
                fontWeight: 'bold',
                marginLeft: ['m', null, 0],
              }}
            >
              “That great pub by the viaduct”
            </Text>
            {/* <Button variant="secondary">View our gallery</Button> */}
          </Box>
        </Box>
      </Grid>
    </Grid>
  )
}
