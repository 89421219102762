import { useQuery } from '@apollo/client'
import { CALCULATE_COST } from 'queries/rooms'

export default function useBookingCost({
  start,
  end,
  resourceId,
  skipQuery,
  extras = [],
}) {
  const shouldSkipQuery = !resourceId || skipQuery || !start || !end

  return useQuery(CALCULATE_COST, {
    variables: {
      booking: {
        resources: [
          {
            id: resourceId,
            start,
            end,
            quantity: 1,
          },
        ],
        extras,
      },
    },
    skip: shouldSkipQuery,
  })
}
