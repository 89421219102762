/** @jsx jsx */
import { jsx, Grid, Box, Divider, Heading, Text } from 'theme-ui'
import { useStaticQuery, graphql, Link } from 'gatsby'

import BackgroundImage from 'gatsby-background-image'
import { path } from 'ramda'
import ReactMarkdown from 'react-markdown'
import { EventDate } from 'components'

export function LiveEvents() {
  const imagesQuery = useStaticQuery(graphql`
    query EventsImages {
      events: allContentfulEvent(sort: { fields: eventStart, order: DESC }) {
        edges {
          node {
            id
            title
            content {
              content
            }
            eventStart
            eventEnd
            image {
              sizes(maxWidth: 1280) {
                ...GatsbyContentfulSizes
              }
            }
          }
        }
      }
      guitar: file(relativePath: { eq: "guitar@2x.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1680) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  const { events } = imagesQuery

  const event = path(['edges', 0, 'node'], events)

  return (
    <BackgroundImage
      fluid={imagesQuery.guitar.childImageSharp.fluid}
      backgroundColor={`#040e18`}
    >
      <Grid
        sx={{ minHeight: [null, null, '100vh'], paddingY: ['l', 'xl', 'xxl'] }}
        variant="center.outer"
      >
        <Grid variant="center.inner" sx={{ position: 'relative', zIndex: 2 }}>
          <Box
            px={['m', 'l', 'xl', 'xxl']}
            py={['l', 'xl', 'xxl']}
            bg="white.100"
            mx={['m', 'l', 'auto']}
            my={['xxxl', null, 'auto']}
            sx={{ maxWidth: 480, boxSizing: 'content-box' }}
          >
            <Box variant="stnd.xxs" mb="s" sx={{ color: 'green.dark' }}>
              <EventDate
                eventStart={event?.eventStart}
                eventEnd={event?.eventEnd}
              />
            </Box>

            <Heading variant="heading.l" sx={{ color: 'orange.dark' }}>
              {event.title}
            </Heading>
            <Divider />

            <Grid gap="l">
              <Box
                sx={{
                  '& p:last-of-type': {
                    marginBottom: 0,
                  },
                }}
              >
                <ReactMarkdown source={event?.content?.content} />
              </Box>

              <Link
                sx={{ textDecoration: 'none', color: 'orange.dark' }}
                to="/events"
              >
                <Text variant="subhead">View events →</Text>
              </Link>
            </Grid>
          </Box>
        </Grid>
      </Grid>
    </BackgroundImage>
  )
}
