import {
  compose,
  ifElse,
  isNil,
  complement,
  when,
  apply,
  map,
  any,
  add,
} from 'ramda'

import {
  isDate,
  subDays,
  addMinutes,
  subMinutes,
  parseJSON,
  format,
  parseISO,
  set,
  getYear,
  getMonth,
  getDate,
  differenceInDays,
  addDays,
  formatISO,
} from 'date-fns/fp'

const notNil = complement(isNil)

export const dateFormat = format('yyyy-MM-dd')
export const eventDateTime = format('EEEE do MMMM yyyy')

export const eventFormat = compose(eventDateTime, parseISO)

export const bookingDateFormat = compose(format('E dd MMM'), parseJSON)

export const bookingFormatPlusOneDay = compose(
  format('E dd MMM'),
  addDays(1),
  parseJSON
)

export const maybeToDate = (date) => (date ? date.toDate() : null)

export const formatBookingDate = when(notNil, compose(dateFormat, parseJSON))

export const daysDifference = when(
  any(notNil),
  compose(add(1), apply(differenceInDays), map(parseJSON))
)

export const maybeFormatDate = (date) =>
  date ? dateFormat(maybeToDate(date)) : null

export function mergeDateTime(date, time) {
  if (date && time) {
    return set(
      { year: getYear(date), month: getMonth(date), date: getDate(date) },
      parseForDisplay(time)
    )
  } else {
    return null
  }
}

export function parseForDisplay(dt) {
  const date = isDate(dt) ? dt : new Date(dt)
  const originalTZO = date.getTimezoneOffset()
  return originalTZO >= 0
    ? addMinutes(originalTZO, date)
    : subMinutes(Math.abs(originalTZO), date)
}

const notDate = complement(isDate)

export const maybeParseDate = when(notNil, compose(when(notDate, parseJSON)))

const subOneDay = subDays(1)

export const maybeMinusDay = when(
  notNil,
  ifElse(isDate, subOneDay, compose(subOneDay, parseJSON))
)
