import React, { useState, createContext, useContext, useEffect } from 'react'
import { maybeMinusDay } from './dates'

export const AppContext = createContext({
  modalOpen: false,
  onOpen: () => void 0,
  onDismiss: () => void 0,
  modalContent: null,
  setModalContent: () => void 0,
})

export function AppContextProvider(props) {
  const { children } = props

  const [showDialog, setShowDialog] = useState(false)
  const onOpen = () => setShowDialog(true)
  const onDismiss = () => setShowDialog(false)
  const [modalContent, setModalContent] = useState(null)
  const [activeRoom, setActiveRoom] = useState(null)

  const [{ startDate, endDate }, setDates] = useState({
    startDate: null,
    endDate: null,
  })

  const [niceDates, setNiceDates] = useState({
    startDate: null,
    endDate: null,
  })

  const { niceStartDate, niceEndDate } = niceDates

  return (
    <AppContext.Provider
      value={{
        isOpen: showDialog,
        onDismiss,
        onOpen,
        modalContent,
        setModalContent,
        activeRoom,
        setActiveRoom,
        startDate,
        endDate,
        setDates,
        niceStartDate,
        niceEndDate,
        niceEndDateMinusDay: maybeMinusDay(niceEndDate),
        setNiceDates,
      }}
    >
      {children}
    </AppContext.Provider>
  )
}

export function useAppContext() {
  return useContext(AppContext)
}
