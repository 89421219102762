import gql from 'graphql-tag'

export const BookingFragment = gql`
  fragment BookingFragment on Booking {
    id
    status
    firstName
    lastName
    address1
    address2
    city
    postCode
    county
    country
    phone
    cost
    bookingToken
    status
  }
`

export const AccountFragment = gql`
  fragment AccountFragment on Account {
    id
    email
    firstName
    lastName
  }
`

export const ResourceFragment = gql`
  fragment ResourceFragment on Resource {
    id
    title
    identifier
    blockCost
    dailyCost
    firstAvailability
    lastAvailability
    nextAvailableDates {
      day
      fullDay
    }
  }
`

export const BookingResourceFragment = gql`
  fragment BookingResourceFragment on BookingResource {
    start
    end
    id
  }
`

export const AvailabilityFragment = gql`
  fragment AvailabilityFragment on Availability {
    id
    blockSize
    timeFrom
    timeUntil
    startTime: start
    endTime: end
  }
`

export const ItemisedObjectFragment = gql`
  fragment ItemisedObjectFragment on ItemisedObject {
    start
    end
    title
    identifier
    blockCost
    totalCost
  }
`
