/** @jsx jsx */
import { jsx, Box } from 'theme-ui'
import { useState, useCallback, useMemo } from 'react'
import { dateFormat } from 'utils/dates'
import { map, any, includes } from 'ramda'
import eachDayOfInterval from 'date-fns/fp/eachDayOfInterval'

import 'react-dates/initialize'
import 'react-dates/lib/css/_datepicker.css'

import { DateRangePicker } from 'react-dates'

import { useAppContext } from 'utils/app-context'
import useDisabledDays from 'hooks/use-disabled-days'

const getRange = (startDate, endDate) =>
  startDate && endDate
    ? map(
        dateFormat,
        eachDayOfInterval({
          start: startDate.toDate(),
          end: endDate.toDate(),
        })
      )
    : []

export default function BookingCalendar() {
  const { setDates, startDate, endDate, activeRoom } = useAppContext()
  const [focusedInput, setFocused] = useState(false)
  const disabledDays = useDisabledDays(activeRoom)

  const handleDateChange = useCallback(setDates, [startDate, endDate, setDates])

  return (
    <Box>
      <DateRangePicker
        block
        numberOfMonths={1}
        startDateId="startDate"
        endDateId="endDate"
        startDate={startDate}
        endDate={endDate}
        displayFormat="ddd MMM D"
        autoFocus
        isDayBlocked={(day) => {
          const niceDay = dateFormat(day.toDate())
          return disabledDays.includes(niceDay)
        }}
        onDatesChange={handleDateChange}
        focusedInput={focusedInput}
        onFocusChange={(focusedInput) => {
          setFocused(focusedInput)
        }}
      />
    </Box>
  )
}
