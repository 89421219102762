import React from 'react'
import { ThemeProvider } from 'theme-ui'

import 'components/base.css'
import { Header, Footer, RoomModal } from 'components'
import theme from 'utils/theme'
import { useAppContext } from 'utils'

export function Layout(props) {
  const { children, location } = props
  const { onDismiss, isOpen } = useAppContext()

  const hideLayout = location?.pathname?.includes('/gallery')

  return (
    <>
      <link
        href="https://fonts.googleapis.com/css2?family=Manrope:wght@500;600;800&display=swap"
        rel="stylesheet"
      />
      <ThemeProvider theme={theme}>
        <RoomModal onDismiss={onDismiss} isOpen={isOpen} />
        <Header key={location?.key} />

        {children}

        <Footer />
      </ThemeProvider>
    </>
  )
}
