/** @jsx jsx */
import { jsx, Box, Flex, Grid, Heading, Text, Divider, Button } from 'theme-ui'
import { useStaticQuery, graphql, navigate } from 'gatsby'
import React from 'react'
import Image from 'gatsby-image'
import { take, cond, equals, always, includes, T } from 'ramda'

import PawIcon from 'images/svg/paw-icon.svg'
import BedIcon from 'images/svg/bed-icon.svg'

import { useAppContext, renderContent } from 'utils'

import { RoomAvailability } from 'components'
import Calendar from 'components/calendar'

const getFeatureIcon = cond([
  [
    includes('Dogs Permitted'),
    always(<PawIcon sx={{ display: 'block' }} width={24} height={21} />),
  ],
  [
    includes('Pull Out Bed Available'),
    always(<BedIcon sx={{ display: 'block' }} width={25} height={22} />),
  ],
  [T, always(null)],
])

export const CONTENTFUL_ROOMS = graphql`
  query RoomsImages {
    northRooms: contentfulRoomType(slug: { eq: "north-facing" }) {
      ...RoomTypeFragment
      rooms {
        ...RoomFragment
      }
    }
    southRooms: contentfulRoomType(slug: { eq: "south-facing" }) {
      ...RoomTypeFragment
      rooms {
        ...RoomFragment
      }
    }
  }
`

export const RoomFragment = graphql`
  fragment RoomFragment on ContentfulRoom {
    id
    name
    identifier
    nightlyCost
    singleNightlyCost
    features
    bedTypes
    description {
      raw
      json: raw
    }
    photo {
      sizes(maxWidth: 1280) {
        ...GatsbyContentfulSizes
      }
    }
  }
`

export const RoomTypeFragment = graphql`
  fragment RoomTypeFragment on ContentfulRoomType {
    id
    slug
    name
    shortName
    shortDescription
    coverImage {
      sizes(maxWidth: 1280) {
        ...GatsbyContentfulSizes
      }
    }
  }
`

export function Room(props) {
  const { room, showAvailability = true } = props

  const { onOpen, setModalContent, setActiveRoom } = useAppContext()

  const roomFeatures = room?.features
  //

  const RoomHeader = () => (
    <Box>
      <Heading variant="heading.l">{room.name}</Heading>
      <Divider my="s" />
    </Box>
  )

  const RoomDetails = () => (
    <Grid gap="s">
      {room.bedTypes && (
        <Text variant="subhead">{room.bedTypes.join(' or ')}</Text>
      )}

      {roomFeatures && (
        <Grid>
          {roomFeatures.map((option) => (
            <Flex sx={{ fontSize: 'xxs', alignItems: 'center' }}>
              <Box sx={{ marginRight: 'xs', color: '#000' }}>
                {getFeatureIcon(option)}
              </Box>
              {option}
            </Flex>
          ))}
        </Grid>
      )}

      <Text
        variant="stnd.body"
        sx={{
          display: 'flex',
          alignItems: 'flex-end',
          lineHeight: 1,
          py: 5,
          marginTop: 'auto',
        }}
      >
        £{room.nightlyCost} / Night{' '}
        <Text variant="stnd.sub" sx={{ ml: 'xs', color: 'black.50' }}>
          £{room.singleNightlyCost} / Single
        </Text>
      </Text>
    </Grid>
  )

  const ModalContent = ({ showAvailability }) => (
    <Box>
      <Box mb="m">
        <RoomHeader />
      </Box>

      <Grid columns={2} gap={['s', 'm', 'l', 'xl']}>
        <Box sx={{ paddingTop: 'm' }}>
          <Box mb="s">
            <RoomDetails />
          </Box>

          <Box mb="l" sx={{ maxWidth: '26em' }}>
            {renderContent(JSON.parse(room.description.json))}
          </Box>
        </Box>
        <Box
          sx={{
            boxShadow: '0 5px 10px rgba(0, 0, 0, 0.05)',
            borderRadius: 10,
            border: 'solid 1px',
            borderColor: 'black.5',
            padding: 'm',
          }}
        >
          <Text variant="subhead" mb="s">
            Choose Dates
          </Text>
          <Box sx={{ marginBottom: 's' }}>
            <Calendar />
          </Box>
          <Box>
            <RoomAvailability />
          </Box>
        </Box>
      </Grid>
    </Box>
  )

  const handleOpenModal = (identifier) => {
    navigate(`/accommodation?id=${identifier}`)
    setActiveRoom(identifier)
    setModalContent(<ModalContent />)
    onOpen()
  }

  return (
    <Flex
      key={room.name}
      bg="white.100"
      sx={{ boxShadow: 'l', flexDirection: 'column' }}
    >
      <Box p="l" sx={{ flex: 'auto' }}>
        <RoomHeader />
        <RoomDetails />
      </Box>
      <Grid columns="1fr 1fr" gap={1}>
        <Button
          variant="square"
          onClick={() => handleOpenModal(room.identifier)}
        >
          Book now
        </Button>
        <Button
          variant="square"
          bg="orange.light"
          onClick={() => handleOpenModal(room.identifier)}
        >
          View details
        </Button>
      </Grid>
    </Flex>
  )
}

Rooms.defaultProps = { count: 6, includeTypes: false }

export function Rooms(props) {
  const { includeTypes, count } = props

  const accommodationQuery = useStaticQuery(CONTENTFUL_ROOMS)

  const { northRooms, southRooms } = accommodationQuery

  const joinedRooms = [...northRooms.rooms, ...southRooms.rooms]

  const gridStyles = {
    columnGap: ['m', null, 'l'],
    rowGap: ['xl', 'xxl', 'xxxxl'],
  }

  if (includeTypes) {
    return (
      <Grid sx={gridStyles}>
        <RoomGroup roomType={northRooms} />
        <RoomGroup roomType={southRooms} />
      </Grid>
    )
  }

  return (
    <RoomGrid>
      {take(count, joinedRooms).map((room) => (
        <Room room={room} key={room.name} />
      ))}
    </RoomGrid>
  )
}

export function RoomGroup(props) {
  const { roomType, hideTopSection } = props

  return (
    <Box>
      {!hideTopSection && (
        <Box mb={['xl', null, 'xxl']}>
          <RoomTypeSection
            title={roomType.shortName}
            shortDescription={roomType.shortDescription}
            image={roomType?.coverImage?.sizes}
          />
        </Box>
      )}

      <RoomGrid>
        {roomType.rooms.map((room) => (
          <Room room={room} key={room.name} />
        ))}
      </RoomGrid>
    </Box>
  )
}

export function RoomGrid(props) {
  const { children } = props
  return (
    <Grid
      variant="boxes.container"
      columns={[null, 'repeat(2,1fr)', 'repeat(3,1fr)']}
      mx={[0, null, 'auto']}
      sx={{ gap: ['m', null, 'l'] }}
    >
      {children}
    </Grid>
  )
}

function RoomTypeSection(props) {
  const { image, title, shortDescription } = props
  return (
    <Grid
      columns={[null, null, 'repeat(3,1fr)']}
      gap={['m', null, 'l']}
      sx={{ alignItems: 'center' }}
    >
      <Box>
        <Heading variant="heading.xl">{title}</Heading>
        <Divider />
        <Box>{shortDescription}</Box>
      </Box>
      <Box
        sx={{
          gridColumn: [null, null, '2/4'],
          borderRadius: 5,
          overflow: 'hidden',
          boxShadow: 'm',
        }}
      >
        {image && <Image fluid={image} />}
      </Box>
    </Grid>
  )
}
