import { useQuery } from '@apollo/client'
import { useCallback } from 'react'
import { maybeFormatDate, maybeToDate, mergeDateTime } from 'utils/dates'
import { GET_ROOM } from 'queries/rooms'
import { useAppContext } from 'utils/app-context'
import useBookingCost from './use-booking-cost'

export default function useRoom({ startDate, endDate, identifier }) {
  const skipQuery = !identifier || !startDate || !endDate

  const {
    setNiceDates,
    niceStartDate,
    niceEndDate,
    niceEndDateMinusDay,
  } = useAppContext()

  const handleData = useCallback(
    (res) => {
      const firstAvailability = res?.room?.firstAvailability
      const lastAvailability = res?.room?.lastAvailability

      setNiceDates({
        niceStartDate: mergeDateTime(
          maybeToDate(startDate || endDate),
          firstAvailability
        ),
        niceEndDate: mergeDateTime(
          maybeToDate(endDate || startDate),
          lastAvailability
        ),
      })
    },
    [setNiceDates, startDate, endDate]
  )

  const roomQuery = useQuery(GET_ROOM, {
    variables: {
      identifier,
      startDate: maybeFormatDate(startDate),
      endDate: maybeFormatDate(endDate),
      statuses: ['incomplete', 'paid', 'unpaid', 'complete'],
    },
    skip: skipQuery,
    onCompleted: handleData,
  })

  const room = roomQuery?.data?.room

  const resourceId = room?.id

  const dateArgs = {
    start: niceStartDate,
    end: niceEndDateMinusDay,
  }

  const costQuery = useBookingCost({
    ...dateArgs,
    resourceId,
    skipQuery: skipQuery || !resourceId,
  })

  return {
    roomQuery,
    costQuery,
    resourceId,
  }
}
