import React, { useEffect } from 'react'

import { DialogOverlay, DialogContent } from '@reach/dialog'
import VisuallyHidden from '@reach/visually-hidden'
import { useAppContext } from 'utils'
import { Flex, Box } from 'theme-ui'
import { useLocation } from '@reach/router'

import '@reach/dialog/styles.css'

export function RoomModal() {
  const { isOpen, onDismiss, modalContent } = useAppContext()
  const { pathname } = useLocation()

  useEffect(() => {
    onDismiss()
  }, [pathname])

  return (
    <div>
      <DialogOverlay
        style={{
          background: 'hsla(0, 100%, 0%, 0.8)',
          paddingLeft: '3vw',
          paddingRight: '3vw',
        }}
        isOpen={isOpen}
        onDismiss={onDismiss}
      >
        <DialogContent
          aria-label="Modal"
          style={{
            boxShadow: '0px 10px 50px hsla(0, 0%, 0%, 0.33)',
            maxWidth: 960,
            width: '100%',
            margin: '10vh auto',
            outline: 'none',
          }}
        >
          <Flex sx={{ justifyContent: 'flex-end' }}>
            <button className="close-button" onClick={onDismiss}>
              <VisuallyHidden>Close</VisuallyHidden>
              <span aria-hidden>×</span>
            </button>
          </Flex>

          {modalContent}

          {/* <Box pt="m">
            <RoomAvailability />
          </Box>

          <Calendar /> */}
        </DialogContent>
      </DialogOverlay>
    </div>
  )
}
